<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <ReportStudentAvgExamScore :publicKey="user.publicKey" />
      </v-col>
      <v-col cols="12">
        <ReportClassroomsOfStudent :publicKey="user.publicKey" />
      </v-col>
      <v-col cols="12">
        <ReportStudentExamScore :publicKey="user.publicKey" />
      </v-col>
      <v-col cols="12">
        <ReportHomeworksCompleted :publicKey="user.publicKey" />
      </v-col>
      <v-col cols="12">
        <ReportTeachingProgress :publicKey="user.publicKey" />
      </v-col>
      <v-col cols="12">
        <ReportReviewProgress :publicKey="user.publicKey" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ReportStudentAvgExamScore from "@/packages/admin/components/reports/ReportStudentAvgExamScore";
import ReportStudentExamScore from "@/packages/admin/components/reports/ReportStudentExamScore";
import ReportHomeworksCompleted from "@/packages/admin/components/reports/ReportHomeworksCompleted";
import ReportTeachingProgress from "@/packages/admin/components/reports/ReportTeachingProgress";
import ReportReviewProgress from "@/packages/admin/components/reports/ReportReviewProgress";
import ReportClassroomsOfStudent from "@/packages/admin/components/reports/ReportClassroomsOfStudent";

export default {
  name: "report",
  components: {
    ReportClassroomsOfStudent,
    ReportReviewProgress,
    ReportTeachingProgress,
    ReportHomeworksCompleted,
    ReportStudentExamScore,
    ReportStudentAvgExamScore,
  },
  computed: {
    ...mapGetters({
      user: "authentication/user",
    }),
  },
  async mounted() {
    await this.$actions.getClassroomsOfUser({
      publicKey: this.user.publicKey,
      dispatch: "class/setClassroomsOfUser",
    });
  },
};
</script>

<style>
.apexcharts-canvas {
  margin-right: auto !important;
  margin-left: auto !important;
}
</style>
